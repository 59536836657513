import React from 'react';
import cx from 'classnames';
import s from './ProductPrice.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {LayoutContext} from '../../ProductPageApp/ProductPageApp';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISubscriptionPlan} from '../../ProductOptions/SubscriptionPlans/SubscriptionPlan/SubscriptionPlan';
import {MeasurementUnitType} from '../../../graphql/queries-schema';
import {unitsTranslations} from './units-translations';

export interface ProductPriceProps extends ProvidedGlobalProps {
  price: number;
  comparePrice?: number;
  formattedPrice: string;
  formattedComparePrice?: string;
  className?: string;
  plan?: ISubscriptionPlan;
  pricePerUnit?: number;
  formattedPricePerUnit?: string;
  baseMeasurementUnit?: MeasurementUnitType;
  baseQuantity?: number;
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductPrice extends React.Component<ProductPriceProps & IProvidedTranslationProps> {
  private renderPrimary() {
    const {formattedPrice, comparePrice, t, globals, plan} = this.props;

    const shouldShowSubscriptionPlanFrequancy =
      globals.experiments.isSubscriptionPlansEnabled && plan && !plan.isOneTimePurchase;
    const primaryTranslationKey = comparePrice
      ? 'SR_PRODUCT_PRICE_AFTER_DISCOUNT'
      : 'SR_PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT';

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-primary-price" className={s.primary}>
          {formattedPrice}
        </span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(primaryTranslationKey)}
        </span>
        {shouldShowSubscriptionPlanFrequancy && (
          <span data-hook="subscription-plan-frequency" className={s.subscriptionPlanFrequency}>
            &nbsp;
            {t('PRODUCT_PAGE_SUBSCRIPTION_PRICE_DESCRIPTION', {
              frequencyUnitSingular: this.getFrequencyUnitSingularTranslation(),
            })}
          </span>
        )}
      </div>
    );
  }

  private renderSecondary() {
    const {formattedComparePrice, t} = this.props;

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-secondary-price" className={s.secondary}>
          {` ${formattedComparePrice} `}
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('SR_PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </div>
    );
  }

  private getFrequencyUnitSingularTranslation(): string {
    const {t, plan} = this.props;

    switch (plan.frequency) {
      case 'DAY':
        return 'DAY_QA';
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_WEEK');
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_MONTH');
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_YEAR');
    }
  }

  private getUnitTranslations(): string {
    const {t, baseMeasurementUnit} = this.props;
    return t(unitsTranslations[baseMeasurementUnit].abbr);
  }

  public render(): JSX.Element {
    const {
      price,
      comparePrice,
      className,
      formattedPricePerUnit,
      baseQuantity,
      globals: {
        experiments: {isSellingInUnitsEnabled},
      },
    } = this.props;

    if (!price) {
      return null;
    }

    const shouldRenderUnitPrice = isSellingInUnitsEnabled && !!formattedPricePerUnit;

    return (
      <LayoutContext.Consumer>
        {(layoutName) => (
          <div data-hook="product-prices-wrapper" className={className}>
            <div data-hook="product-price" className={cx(s.prices, s[`prices__${layoutName}`])}>
              {comparePrice > 0 && this.renderSecondary()}
              {this.renderPrimary()}
            </div>
            {shouldRenderUnitPrice && (
              //TODO: add SR implementation
              <div data-hook="product-unit-price" className={s.unitPrice}>
                <span data-hook={'formatted-unit-price'}>{`${formattedPricePerUnit}`}</span>
                <span> / </span>
                <span>{baseQuantity}</span>
                <span data-hook={'unit-price-unit-type'}>{this.getUnitTranslations()}</span>
              </div>
            )}
          </div>
        )}
      </LayoutContext.Consumer>
    );
  }
}
